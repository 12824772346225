:root {
  --root: #FE4C4C;
  --selectdropbtn-color: #fff;
  --font-color: #fff;
}

.btn.btn-red {
  background-color: var(--root);
  color: white;
}

.btn.btn-outline-red {
  color: var(--root);
  border-color: var(--root);

}

.btn.btn-outline-red.active {
  background-color: var(--root);
  color: white;
}

.btn.btn-outline-red:hover {
  background-color: var(--root);
  color: white;
}

body {
  font-family: "Karla", sans-serif !important;
  background-color: #d0d0ce;
  background-color: #f5f6fe;
  min-height: 100vh;
}

.brand-wrapper {
  margin-bottom: 19px;
}

.brand-wrapper .logo {
  height: 60px;
}

.login-card {
  border: 0;
  border-radius: 27.5px;
  box-shadow: 0 10px 30px 0 rgba(172, 168, 168, 0.43);
  overflow: hidden;
  background-color: white;
}

.login-card-img {
  border-radius: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.login-card .card-body {
  padding: 85px 60px 60px;
}

@media (max-width: 422px) {
  .login-card .card-body {
    padding: 35px 24px;
  }
}

.login-card-description {
  font-size: 25px;
  color: #000;
  font-weight: normal;
  margin-bottom: 23px;
}

.login-card form {
  max-width: 326px;
}

.login-card .form-control {
  border: 1px solid #d5dae2;
  padding: 15px 25px;
  margin-bottom: 20px;
  min-height: 45px;
  font-size: 13px;
  line-height: 15;
  font-weight: normal;
}

.login-card .login-btn {
  padding: 13px 20px 12px;
  background-color: #000;
  border-radius: 4px;
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  margin-bottom: 24px;
}

.login-card .login-btn:hover {
  border: 1px solid #000;
  background-color: transparent;
  color: #000;
}

.login-card .forgot-password-link {
  font-size: 14px;
  color: #919aa3;
  margin-bottom: 12px;
}

input:focus {
  outline: 1px solid gray !important;
  box-shadow: none !important;
}

.navbar .navbar-brand-wrapper {
  background: #ffffff;
  width: 255px;
  height: 64px;
}

.page-body-wrapper {
  min-height: 100vh;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding-top: 64px;
  background-color: #e8eff9;
}

.navbar .navbar-menu-wrapper {
  background: #ffffff;
  color: #000000;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  width: 100%;
  height: 64px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
  color: #001737;
  font-size: 18px;
  font-style: normal;
  padding: 1.2rem 1.2rem;
  font-weight: 300;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link.active {
  position: relative;
  font-weight: 700;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link.active:after {
  content: '';
  width: 100%;
  left: 0;
  bottom: -2px;
  height: 1px;
  background: var(--root);
  position: absolute;
}

.navbar-menu-wrapper .count-indicator {
  position: relative;
  padding: 0;
  text-align: center;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-menu-wrapper .count-indicator .count {
  position: absolute;
  right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  top: 16px;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  line-height: 1rem;
}

.arrow-hide::after {
  display: none;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
  border: none;
  -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  border-radius: 0px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
  animation-name: dropdownAnimation;
  -webkit-animation-duration: 0.25s;
  -moz-animation-duration: 0.25s;
  -ms-animation-duration: 0.25s;
  -o-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.sidebar {
  min-height: calc(100vh - 64px);
  /*  background: #27293d;*/
  background: #fff;
  /* font-family: "Roboto", sans-serif; */
  font-weight: 300;
  padding: 0;
  z-index: 11;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  box-shadow: 6px 16px 31px -18px #b7bcd1;
  -webkit-box-shadow: 6px 16px 31px -18px #b7bcd1;
  -moz-box-shadow: 6px 16px 31px -18px #b7bcd1;
  -ms-box-shadow: 6px 16px 31px -18px #b7bcd1;
}

.sidebar-offcanvas {
  min-height: calc(100vh - 64px);
  /* background: #27293d; */
  background: #fff;
  /* font-family: "Roboto", sans-serif; */
  font-weight: 300;
  padding: 0;
  width: 320px;
  opacity: 1;
  z-index: 11;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  box-shadow: 6px 16px 31px -18px #b7bcd1;
  -webkit-box-shadow: 6px 16px 31px -18px #b7bcd1;
  -moz-box-shadow: 6px 16px 31px -18px #b7bcd1;
  -ms-box-shadow: 6px 16px 31px -18px #b7bcd1;
}

.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-width: calc(100% - 320px);
  /* min-height: calc(100vh - 64px); */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.main-panel-offcanvas {
  transition: width 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: calc(100vh - 64px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.content-wrapper {
  background: #e8eff9;
  /*    padding: 1.875rem 1.875rem 0 1.875rem;*/
  padding: 1rem 1rem 0 1rem;
  width: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.nav-pills .nav-item {
  margin-right: 1rem;
}

.nav-pills-success .nav-link.active {
  background: var(--root);
}

.nav-pills .nav-link {
  border: 1px solid #e8eff9;
  padding: 0.5rem 1.75rem;
  color: var(--root);
}

.navbar-menu-wrapper .count-indicator i {
  font-size: 1.25rem;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e7eaed;
}

/* .sidebar .sidebar-profile .sidebar-profile-name .sidebar-name {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
  color: #ffffff;
}

.sidebar .sidebar-profile .sidebar-profile-name .sidebar-designation {
  font-size: 0.75rem;
  margin-bottom: 0;
  color: #7e7e7e;
} */

/* .preview-item {
  display: flex;
} */

/* .preview-list .preview-item .preview-thumbnail {
  color: #ffffff;
  position: relative;
}

.preview-list .preview-item .preview-item-content {
  line-height: 1;
  padding-left: 15px;
}

.preview-list .preview-item .preview-thumbnail img,
.preview-list .preview-item .preview-thumbnail .preview-icon {
  width: 36px;
  height: 36px;
  border-radius: 100%;
} */

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .ellipsis {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#sidebar .left-sidebar>li {
  width: 100%;
  padding: 1rem 1.125rem;
}

.selectdropbtn {
  border-radius: 0px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  color: var(--selectdropbtn-color);
  border-color: var(--root) !important;
  /* font-size: 15px !important; */
}

.selectdropbtn:hover {
  border-radius: 0px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  color: var(--selectdropbtn-color);
  border-color: var(--root) !important;
  /* font-size: 15px !important; */
}

.selectdropbtn:focus {
  color: var(--selectdropbtn-color);
  background-color: transparent;
  border-color: none;
  box-shadow: none !important;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
  border-radius: 0px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  color: var(--selectdropbtn-color);
  border-color: var(--root);
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
  outline: none !important;
  outline: none !important;
  outline-offset: 0;
}

.bootstrap-select.show>.btn-light.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--root);
}

.text-theme {
  border-radius: 0px !important;
  border: none;
  background-color: transparent !important;
  border: 1px solid var(--root);
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--font-color);
}

.text-theme:focus,
.text-theme:hover {
  color: var(--font-color);
  outline: none !important;
  border-color: var(--root);
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

.custom-list-group {
  background: transparent;
  border-radius: 0px;
}

.custom-list-group li {
  position: relative;
  display: block;
  padding: 0.50rem 0.50rem;
  background-color: transparent;
  border: 1px solid var(--root);
  color: #fff;
}

.sidebar .nav .nav-item.active>.nav-link {
  background: #414147;
  position: relative;
}

.sidebar .nav .nav-item.active>.nav-link .menu-title,
.sidebar .nav .nav-item.active>.nav-link .menu-arrow {
  color: #fff;
}

.sidebar .nav.sub-menu {
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  padding: 0.25rem 0 0 2.5rem;
}

.sidebar .nav.sub-menu .nav-item {
  position: relative;
  padding: 0;
}

.sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: #fff;
  background: transparent;
}

.sidebar .nav.sub-menu .nav-item .nav-link {
  color: #939393;
  padding: 0.5rem 1rem;
  position: relative;
  font-size: 0.75rem;
  line-height: 1;
  height: auto;
  border-top: 0;
}

.sidebar .nav.sub-menu .nav-item::before {
  content: '';
  width: 2px;
  height: 8px;
  position: absolute;
  margin-top: 0;
  top: 3px;
  border-radius: 0;
  background: #8e95aa;
}

.sidebar .nav.sub-menu .nav-item::after {
  content: '';
  width: 2px;
  height: 8px;
  position: absolute;
  margin-top: 0;
  bottom: 3px;
  border-radius: 0;
  background: #8e95aa;
}

.sidebar .nav .nav-item.active>.nav-link .menu-title,
.sidebar .nav .nav-item.active>.nav-link .menu-arrow {
  color: #fff;
}

.sidebar .nav .nav-item .nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  white-space: nowrap;
  padding: 0.75rem 1.75rem 0.75rem 1.75rem;
  color: #939393;
  -webkit-transition-duration: 0.45s;
  -moz-transition-duration: 0.45s;
  -o-transition-duration: 0.45s;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow {
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: auto;
  margin-right: 0;
  color: #939393;
}

.sidebar .nav .nav-item .nav-link[aria-expanded="true"] i.menu-arrow:before {
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  content: "\e049";
  /* font-family: "typicons"; */
  font-style: normal;
  display: block;
  font-size: 1rem;
  line-height: 10px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.custom-nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.custom-nav-tabs .nav-link:hover {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  /*border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;*/
}

.custom-nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 1px solid var(--root);
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  font-weight: bold;
}

.custom-nav-tabs .nav-link:hover {
  /*  border:none;*/
  color: #333;
}

.bg-1 {
  padding: 0px;
  background: linear-gradient(45deg, #F2709C 0%, #FF9473 100%);
  border-radius: 10px;
}

.space .card-title {
  padding: 0px;
}

.bg-2 {
  padding: 0px;
  background: linear-gradient(45deg, #92D000 0%, #7AC493 100%);
  border-radius: 10px;
}

.bg-3 {
  padding: 0px;
  background: linear-gradient(45deg, #52A7EA 0%, #712E98 100%);
  border-radius: 10px;
}

.bg-4 {
  padding: 0px;
  background: linear-gradient(45deg, #6E7BFB 0%, #9CFCF8 100%);
  border-radius: 10px;
}

.tab-a-icon {
  font-size: 1.5rem;
  padding: 2px;
  margin-right: 15px;
  color: #333;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: var(--root);
  border-color: var(--root);
}

.nav-tabs .nav-link {
  color: var(--root);
}

@keyframes dropdownAnimation {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }

  to {
    opacity: 1;
    transform: none;
    transform: translate3d(0, 0px, 0);
  }
}

.dropdownAnimation {
  animation-name: dropdownAnimation;
  animation-duration: 0.25s;
  animation-fill-mode: both;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

.infinite-spin {
  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@media (max-width: 767px) {
  .login-card-footer-text {
    margin-bottom: 24px;
  }

  .navbar .navbar-menu-wrapper {
    height: auto;
  }

  .sidebar {
    min-height: calc(100vh - 64px);
    background: hsl(0, 0%, 100%);
    /* font-family: "Roboto", sans-serif; */
    font-weight: 300;
    padding: 0;
    width: 80%;
    position: fixed;
    right: 0;
    top: 64px;
    z-index: 11;
    transition: width 0.25s ease, background 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;
    -moz-transition: width 0.25s ease, background 0.25s ease;
    -ms-transition: width 0.25s ease, background 0.25s ease;
    box-shadow: 6px 16px 31px -18px #b7bcd1;
    -webkit-box-shadow: 6px 16px 31px -18px #b7bcd1;
    -moz-box-shadow: 6px 16px 31px -18px #b7bcd1;
    -ms-box-shadow: 6px 16px 31px -18px #b7bcd1;
  }

  .main-panel {
    width: 100% !important;
  }
}

.login-card-footer-nav a {
  font-size: 14px;
  color: #919aa3;
}

.left-side-bg {
  /* background: linear-gradient(180deg, #05E6BD 0%, #02698A 100%); */
  background: linear-gradient(180deg, #05E6BD 0%, #02698A 100%);
  /* background-image: url(../public/login.jpg);
  background-repeat: no-repeat; */
}

.form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
  background-color: var(--bs-form-check-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.form-check-input:checked {
  background-color: #FE4C4C;
  border-color: #FE4C4C;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #FE4C4C;
  border-color: #FE4C4C;
}

.pagination {
  --bs-pagination-color: #FE4C4C;
}

.pagination:hover {
  --bs-pagination-color: #333;
}

i {
  color: #FE4C4C;
}

i:hover {
  color: #333;
}

.sidebar-menu {
  padding: 0;
}

.sidebar-menu li {
  list-style: none;
  display: flex;
  /*    background: #49c9f2;*/
  /*    margin-bottom: 5px;*/
  /*    border-radius: 1px;*/
  color: #000;
  /*  border-bottom: 1px solid #313452;*/
  /*    box-shadow: 1px 1px 3px 0.1px #ddd;*/
  /*    min-height: 35px;*/
}

.sidebar-menu li>a {
  align-self: center;
  padding: .55rem 1rem;
  color: #FE4C4C;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  text-decoration: none;
}

.sidebar-menu li>a:hover {
  /*  background: #fff;*/
  color: #333;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
}

.sidebar-menu li .card {
  width: 100%;
  background: transparent;
  border: none;
}

a {
  text-decoration: none;
  color: #FE4C4C;
}

.collapse a {
  text-decoration: none;
  color: #FE4C4C;
}

.collapse a:hover {
  text-decoration: none;
  color: #333;
}

.sidebar-menu li>.card {
  padding: 0px;
  box-shadow: none;
  border: none;
  background: transparent;
  font-size: 14px;
}

.sidebar-menu li>.card .card-header {
  padding: 0px;
  box-shadow: none;
  border: none;
  background: transparent;
  font-size: 14px;
}

/*.main-sidebar .sidebar-menu li {
    display: block;
}*/

.sidebar-menu li>.card .card-body {
  padding: .55rem 1rem;
}

.sidebar-menu li>.card .card-header a {
  padding: .55rem 1rem;
  width: 100%;
  display: block;
  text-decoration: none;
  color: #FE4C4C;
}

.sidebar-menu li>.card .card-header a:hover {
  width: 100%;
  /*  background: #3a3f51;*/
  color: #333;
}

.sidebar-menu li>.card .card-header a[aria-expanded="false"]:after {
  content: "\f078";
  color: #FE4C4C;
}

.sidebar-menu li>.card .card-header:hover a[aria-expanded="false"]:after {
  content: "\f078";
  color: #333;
}

.sidebar-menu li>.card .card-header a[aria-expanded="true"]:after {
  content: "\f077";
  color: #FE4C4C;
}

.sidebar-menu li>.card .card-header:hover a[aria-expanded="true"]:after {
  content: "\f077";
  color: #333;
}

.sidebar-menu li>.card .card-header a:after {
  position: relative;
  top: 4px;
  display: inline-block;
  /* font-family: 'Font Awesome 6 Free'; */
  font-style: normal;
  font-weight: 900;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  transition: transform .25s linear;
  -webkit-transition: -webkit-transform .25s linear;
}

.card {
  min-height: 10px;
  background-color: #fff;
  /*  box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.05);*/
  border: 1px solid var(--bs-border);
}

.card-body {
  padding: 10px;
}

.card-body p {
  margin: 0px;
}

.card-para {
  font-size: 25px;
}

.card-title {
  padding: 10px;
  color: var(--bs-card-title-color);
  margin: 0px;
}

.Heading {
  color: var(--bs-white);
  /* padding: 10px; */
}

.Heading p {
  margin: 0px;
}

card-body scrollbar table-responsive .scrollbar {
  width: auto;
  height: 151px;
  overflow: auto;
  padding: 0px;
}



/* content-wrapper first-row */

.main-panel .content-wrapper .first-row P {
  margin-top: 0;
  margin-bottom: 0px;
  font-size: 45px;
}

.main-panel .content-wrapper .second-row .card {
  background: #e8eff9;
  padding: 0px;
  margin: 0px;
}

.main-panel .content-wrapper .second-row .card span {
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
}

.main-panel .content-wrapper .third-row .card {
  background: #e8eff9;
  padding: 15px;
}

.fourth-row .card {
  min-height: 42vh;
}

.main-panel .content-wrapper .card .num {
  display: flex;
  justify-content: center;
  padding: 0px;
  margin: 0px;
}

.progress {
  height: 12px;
}

.fa-x25 {
  font-size: 25px;
}

.green {
  color: #7ac493;
}

.red {
  color: #F2709C;
}

.blue {
  color: #52A7EA;
}

.color-header img {
  width: 50px;
}

.color-header span {
  margin-left: 10px;
  font-size: 30px;
}

.color-header p {
  margin-bottom: 0px;
}

.text-end {
  text-align: end;
}


.text-theme:focus,
.text-theme:hover {
  color: black;
  outline: none !important;
  border-color: var(--root);
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

input:focus {
  outline: 1px solid gray !important;
  box-shadow: none !important;
}

.text-theme {
  border-radius: 0px !important;
  border: none;
  background-color: transparent !important;
  border: 1px solid var(--root);
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: black;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-light {
  background-color: #e8eff9;
}

b,
strong {
  font-weight: bolder;
  padding-right: 5px;
}


.PropertyCreditPD button {
  text-decoration: none;
}

.PropertyCreditPD .card-body {
  padding: 0px;
  width: 100%;
}

.PropertyCreditPD .btn-link:hover {
  text-decoration: none;
}

.PropertyCreditPD .btn-link:focus {
  text-decoration: none;
  box-shadow: none;
}

.PropertyCreditPD .form-select {
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-size: 16px 12px;
  border-radius: var(--bs-border-radius);
}

.PropertyCreditPD .form-select:focus {
  box-shadow: none;
}

.PropertyCreditPD #formFile {
  padding: 3px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
  cursor: not-allowed;
}

.table-card {
  background: var(--surface-card);
  /* padding: 0.5rem; */
  padding-bottom: 0;
  border-radius: 10px;
}

.p-datatable-thead {
  border-bottom: 1px solid rgb(192, 192, 192);
}

.p-datatable .p-datatable-header {
  background: none;
  padding: 0 0 1rem 0;
}

.p-column-title {
  font-size: 14px;
}

.p-paginator-bottom .p-paginator .p-component {
  padding: 0;
}

/* toast message box */

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
  height: 1.5rem;
  margin-top: 8px;
}

.p-toast-detail {
  margin: 0;
}

/* dropdown */

.product-droppdown {
  .p-inputtext {
    bottom: 6px;
    position: relative;
  }
}

.p-checkbox-box {
  border: 1px solid gray;
}

.p-dropdown-filter-container .p-inputtext {
  padding: 0.3rem;
}

.p-dialog-content {
  overflow: visible;
}

.p-datepicker {
  top: -127px !important;
}

.p-datepicker table td>span {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
}

.p-datepicker-buttonbar {
  justify-content: space-around;
}

.modules-accordian .p-accordion-header-link {
  /* background-color: rgb(228, 228, 228); */
  background-color: rgb(224, 224, 224);
}

.modules-accordian .p-accordion-content {
  background-color: rgb(235, 235, 235);
}

.modules-accordian .p-accordion-header-link:hover {
  color: rgb(0, 0, 0);
}

.modules-accordian .p-accordion-header-link {
  color: rgb(0, 0, 0);
}

.highcharts-credits {
  display: none;
}

.login-background {
  background-image: url(../public/background1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.p-datatable-thead>tr>th>.p-column-header-content {
  text-align: center;
  justify-content: center;
}

.eventDropdown>.p-dropdown-label.p-inputtext {
  padding: 0.25rem !important;
}

.home-pie>div>.highcharts-container {
  height: 350px !important;
}

.datePicker-table>.p-inputtext.p-component {
  background: none;
  font-size: 14px;
  padding: 4px 0;
}

.dateTime-realtable>.p-inputtext.p-component {
  background: none;
  font-size: 12px;
  padding: 3px 0;
}

.p-accordion-header-link {
  padding: 0.8rem;
}

.p-paginator-page.p-paginator-element.p-link.p-highlight {
  background: #ffe3e3;
  border-color: #eef2ff;
  border-radius: 50%;
  color: #fe4c4c !important;
}

.event-filter .p-inputtext {
  padding: 0.45rem !important;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
  border-width: 1px;
}

.p-datatable-thead>tr>th>.p-column-header-content .p-column-title {
  text-wrap: nowrap !important;
}

.ctp-multiselect .p-multiselect-label-container {
  display: flex;
  align-items: center;
  padding: 5px;
}

.ctp-multiselect .p-multiselect-label.p-placeholder {
  padding: 3px !important
}

.ctp-multiselect .p-multiselect-label {
  padding: 0 !important
}

.ctp-multiselect .p-multiselect-token {
  padding: 5px !important;
  font-size: 14px !important;
}

/* .p-dropdown-items {
  position: absolute;
  background-color: white;
  z-index: 1000;
} */


.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text {
  position: absolute;
  left: -320%;
  bottom: 120%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  z-index: 1;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 100;
  font-family: inherit;
}

.tooltip-text::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 90%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.icon-tooltip:hover+.tooltip-text {
  opacity: 1;
  visibility: visible;
}
